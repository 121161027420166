import { useEffect } from 'react';
import SEO from './seo/SEO.js';

import BackToTopButton from './buttons/BackToTopButton.js';

function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo({ top: 200, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <SEO
                title={'Поверителност - Денас Център'}
                description={'Нашата политика за поверителност и използване на бисквитки (cookies).'}
                canonicalUrl={'https://www.denasbg.com/privacy-policy'}
            />

            <div className="container col-lg-8 py-3 wow zoomIn" data-wow-delay="0.3s">
                <h1 className="text-center">
                    Политика за поверителност и използване на бисквитки (cookies)
                </h1>
                <br></br>
                <h5> Данни за администратора на лични данни </h5>
                <ul>
                    <li>
                        Денас Център БГ - ЕООД е администратор на лични данни по смисъла на Регламент (ЕС) 2016/679 на Европейския парламент и на Съвета от 27 април 2016 година относно защитата на физическите лица във връзка с обработването на лични данни и относно свободното движение на такива данни и за отмяна на Директива 95/46/EО (по-долу „Регламента”) и Закона за защита на личните данни (по-долу „ЗЗЛД“).
                        Интернет страницата https://www.denasbg.com/ се притежава и управлява от Денас Център БГ - ЕООД в пълно съответствие с разпоредбите на Регламента и ЗЗЛД.
                    </li>
                </ul>
                <h5> Данни за контакт с администратора:</h5>
                <ul>
                    <li> +359 882 21 12 74 </li>
                    <li> Имейл: recreation.center17@gmail.com </li>
                </ul>
                <h5> Принципи на обработване. Правно основание </h5>
                <ul>
                    <li>
                        Денас Център БГ - ЕООД не продава лични данни, събрани от дружеството, на трети лица, нито обработва или предоставя тези данни на трети лица без правно основание за това съгласно Регламента и ЗЗЛД. Денас Център БГ - ЕООД обработва и предоставя лични данни на трети лица само при наличие на надлежно правно основание за това, включително изрично и свободно дадено съгласие по силата на Регламента и/или на друго основание за обработване, предвидено в Регламента и ЗЗЛД.
                        Възможно е да се наложи по закон, при съдебен/арбитражен/ изпълнителен/обезпечителен процес и/или по искане от компетентен орган да бъдат разкрити лични данни, както и ако това е необходимо за целите на националната сигурност, правоприлагането или други предвидени в законодателството случаи.
                        Възможно е и да бъдат разкрити лични данни, ако това разкриване е обосновано необходимо за защитаване на легитимните интереси на фирмата. В случай на преобразуване или продажба на цялото или част от търговското предприятие е възможно Денас Център БГ - ЕООД да прехвърли събрани лични данни на съответното новообразувано/преобразувано дружество или дружество – правоприемник.
                        При обработването на личните данни , стриктно се спазва принципа за поддържане на минимум обработвани лични данни ( принцип need-to-know).
                    </li>
                </ul>
                <h5> Лични данни </h5>
                <ul>
                    <li>
                        Лични данни са всяка информация, свързана с идентифицирано физическо лице или физическо лице, което може да бъде идентифицирано на база съответните данни.
                    </li>
                </ul>
                <h5> Цели за обработване на лични данни </h5>
                <ul>
                    <li>
                        Денас Център БГ - ЕООД събира и обработва личните данни, които са необходими за извършване на дейността му, както и лични данни на потребители на сайта, телефон за връзка посочен по-горе , когато чрез формата за контакт на сайта, по имейл или чрез телефон е подадено запитване, рекламация, оплакване, жалба или друг сигнал от потребител.
                    </li>
                </ul>
                <h5> Денас Център БГ - ЕООД обработва лични данни с цел : </h5>
                <ul>
                    <li>
                        да отговори и предприеме действия по подаденото запитване, рекламация, оплакване, жалба или друг сигнал от потребител;
                    </li>
                    <li>
                        да поддържа отчетност относно подадените запитвания, рекламации, оплаквания, жалби или друг сигнали от потребители, вкл. за целите на контрол и надзор от страна на публични органи и производствата пред тях.
                    </li>
                    <li>
                        Със съгласие на потребителя, или когато е допустимо по закон, Денас Център БГ - ЕООД може да използва личните данни на потребители, а именно имейл адрес и имена, за маркетингови цели, включващи свързване с потребителя с цел предоставяне на информация, новини и оферти. Ако са се съгласили да получават такава маркетингова информация, потребителите могат по всяко време да оттеглят съгласието си чрез предоставената във всеки маркетинг имейл функционалност (линк) за тази цел.
                    </li>
                </ul>

                <h5> Категории обработвани лични данни </h5>
                <ul>
                    <li>
                        Денас Център БГ - ЕООД обработва следните категории лични данни:
                    </li>
                    <li>
                        За физически лица, които са подали запитване, рекламация, оплакване, жалба или друг сигнал Денас Център БГ - ЕООД обработва личните данни, които самият потребител доброволно е посочил във формата за контакт, телефонния разговор, имейла или друга кореспонденция с дружеството (и така е дал съгласие за обработването им). Дружеството изисква от потребителя име, телефон и адрес за връзка като минимум за извършване на проверка и изготвяне на отговор до потребителя.
                    </li>
                    <li>
                        Когато потребители изпращат запитване, рекламация, оплакване, жалба или друг сигнал до Денас Център БГ - ЕООД чрез формата за контакт на сайта, по имейл или телефон на потребителя се презюмира, че потребителите са дали своето свободно, изрично и информирано съгласие за обработване на личните данни, доброволно включени от тях в съответното запитване, рекламация, оплакване, жалба или друг сигнал.
                    </li>
                </ul>
                <h5> Категории обработвани лични данни </h5>
                <ul>
                    <li>
                        Бисквитките (cookies) са малки файлове с информация, които се изпращат от уеб сървъра и се запазват в интернет браузъра на потребителя (напр. данни за използван език, време на връзка, посетени интернет страници) или в твърд диск, като след това се връщат от интернет браузъра към сървъра всеки път, когато бъде получен достъп до този сървър при посещение на съответния интернет сайт.
                    </li>
                    <li>
                        Интернет страницата на Денас Център БГ - ЕООД използва бисквитки, за да разграничава потребителите едни от други, да предоставя на потребителите по-добри услуги и да подобрява сайта си. Приемането на настоящата Политика в частта за бисквитките става с кликване от страна на потребителя на изскачащото съобщение (pop-up) на сайта , както и когато потребителят продължи да използва сайта. В случай, че потребител не е съгласен с настоящата Политика, той/тя е длъжен веднага да преустанови използването на сайта.
                    </li>
                </ul>
                <h5> Мерки за сигурност </h5>
                <ul>
                    <li>
                        Денас Център БГ - ЕООД взима достатъчни технически и организационни мерки, за да защити личните данни, които обработва, от кражба, злоупотреба, неоторизиран достъп, неоторизирано разкриване, неоторизирано унищожаване или всякакво друго незаконосъобразно обработване или разпореждане с тези данни.
                    </li>
                    <li>
                        Всички представители и служители на Денас Център БГ - ЕООД, както и всички съдоговорители на дружеството, са задължени да спазват конфиденциалност и да прилагат стриктно законодателството в областта на защита на личните данни.
                    </li>
                    <li>
                        Когато потребители изпращат запитване, рекламация, оплакване, жалба или друг сигнал , чрез формата за контакт на сайта, по имейл или телефон Денас Център БГ - ЕООД може да предоставя личните данни на тези потребители на: (а) служители на дружеството в отдела му по контрол на качеството и отдел „Маркетинг“; (2) служители на дружеството на мениджърски позиции, а именно директор на производствен отдел, директор на търговски отдел, директор на логистичен отдел и съответния регионален търговски мениджър, според случая; (3) външни заинтересовани лица.
                    </li>
                    <li>
                        Съгласно Регламента и ЗЗЛД субектите на данни разполагат със следните права по всяко време: (1) право на достъп до личните им данни, обработвани от Денас Център БГ - ЕООД ; (2) право да изискват коригиране на неточни данни, изтриване (при наличие на законово основание за това), ограничаване или блокиране (при наличие на законово основание за това) на обработването на личните им данни, обработвани от Денас Център БГ - ЕООД ; (3) право на преносимост на данните при наличие на условията за това съгласно Регламента; (4) правото да възразяват по всяко време срещу обработването на личните им данни, както и право да оттеглят съгласието си за обработване, без да се засяга законосъобразността на обработването до оттеглянето му, когато има законови основания за това; (5) право на жалба до Комисия за защита на личните данни (КЗЛД), ако считат, че са нарушени правата им във връзка със защитата на личните им данни.
                    </li>
                    <li>
                        Тази политика влиза в сила на 1.09.2021 г. И Денас Център БГ - ЕООД може да изменя и актуализира тази политика, като всяко изменение/актуализация се публикува на официалния уебсайт на дружеството, като по своя преценка Денас Център БГ - ЕООД може да предприеме и други действия за уведомяване на потребителите за изменената или актуализирана политика.
                    </li>
                </ul>
            </div>
            {<BackToTopButton />}
        </>
    );
}

export default PrivacyPolicy;