import { useEffect } from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
    useEffect(() => {
        window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container text-center">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
                        <h1 className="display-1">404</h1>
                        <h1 className="mb-4">Page Not Found</h1>
                        <p className="mb-4">Съжаляваме, страницата, която търсите, не съществува в нашия уебсайт!</p>
                        <Link to={'/'} className="btn btn-primary rounded-pill py-3 px-5" href="">Към Начална Страница</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;