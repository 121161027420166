import { useEffect } from 'react';
import SEO from '../seo/SEO';

import { useLocation } from 'react-router-dom';

import ProcedureItem from './ProcedureItem';
import BackToTopButton from '../buttons/BackToTopButton';

function ProcedureList({
    procedures,
}) {
    useEffect(() => {
        window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
    });

    const location = useLocation();
    const pathName = location.pathname;

    let category = '';
    if (pathName === '/body-procedures') {
        category = 'Процедури за Тяло';
    } else if (pathName === '/eyebrows-procedures') {
        category = 'Процедури за Вежди';
    } else if (pathName === '/eyelashes-procedures') {
        category = 'Процедури за Мигли';
    } else if (pathName === '/face-procedures') {
        category = 'Процедури за Лице';
    } else if (pathName === '/therapies') {
        category = 'Терапии и физиотерапии';
    }


    return (
        <>
            <SEO
                title={`${category} - Денас`}
                description={'Открийте изкуството на красотата в нашето козметично студио. Разгледайте нашите първокласни услуги, пригодени да подобрят вашата естествена привлекателност.'}
                canonicalUrl={`https://www.denasbg.com${pathName}`}
            />

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title bg-white text-center text-primary px-3">Услуги</h6>
                        <h1 className="mb-5">{category}</h1>
                    </div>
                    <div className="row g-4 justify-content-center">
                        {procedures.map(procedure => <ProcedureItem key={procedure.name} {...procedure} />)}
                    </div>
                </div>
                {<BackToTopButton />}
            </div>
        </>
    );
};

export default ProcedureList;