import { useEffect } from 'react';
import SEO from '../seo/SEO';
import BackToTopButton from '../buttons/BackToTopButton';
import styles from './Elysion.module.css';

import { benefits, questions, prices } from '../../data/elysionData';

function Elysion() {
    useEffect(() => {
        window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <SEO
                title='Elysion лазерна епилация'
                description='Elysion лазерна епилация'
                canonicalUrl='https://www.denasbg.com/elysion'
            />

            <div className='container-xxl py-5'>
                <div className='container wow fadeInUp' data-wow-delay="0.1s">
                    <h1 className='text-center'>Elysion-pro лазерна епилация</h1>
                    <br />
                    <div className='row gy-4 gx-4 mb-4'>
                        <div className={`col-lg-6 col-md-12 ${styles['col-custom']}`}>
                            <img src='/img/elysion/elysion-1.png' alt='Elysion Laser' />
                        </div>
                        <div className={`col-lg-6 col-md-12 ${styles['col-custom']}`}>
                            <img src='/img/elysion/elysion-logo.png' alt='Elysion Logo' />
                            <strong>
                                <p>Elysion Pro 3500W е диоден лазер за епилация от ново поколение.</p>
                                <p>Elysion-Pro 3500W е проектиран така, че да предоставя висока ефективност на процедурата. Той е олицетворение на отдавна търсената гъвкавост и прецизност при диодните лазери за епилация и изпреварва по ефективност и по-високите класове, без да има опасност от увреждане на кожата.</p>
                                <p>Лазерната епилация, за разлика от останалите методи за обезкосмяване, има изключително траен ефект. При нея специализирана апаратура таргетира космения фоликул със светлинни лъчи, които преминават през кожата и се абсорбират от пигмента в косъма. Така косъмът се унищожава, без да се засягат тъканите покрай него. Ние извършваме лазерна епилация с последно поколение лазер и внимание към вида кожа на всеки пациент.</p>
                                <p>Видими резултати още след първата процедура.</p>
                            </strong>
                        </div>
                    </div>
                </div>

                <div className='container wow fadeInUp' data-wow-delay="0.2s">
                    <h2 className='text-center'>Предимства на лазерната епилация с Elysion-Pro 3500 W</h2>
                    <br />
                    <div className='row gy-4 gx-4 mb-4'>
                        <div className={`col-lg-6 col-md-12 ${styles['col-custom']}`}>
                            <img src='/img/elysion/elysion-logo.png' alt='Elysion Logo' />
                            <strong>
                                <ul>
                                    {benefits.map((benefit, index) => (
                                        <li key={index}>{benefit}</li>
                                    ))}
                                </ul>
                            </strong>
                        </div>
                        <div className={`col-lg-6 col-md-12 ${styles['col-custom']}`}>
                            <img src='/img/elysion/aplicator.png' alt='Elysion Applicator' />
                        </div>
                    </div>
                </div>

                <div className='container wow fadeInUp' data-wow-delay="0.3s">
                    <h2 className='text-center'>Охладителна система на Elysion-Pro 3500 W</h2>
                    <br />
                    <br />
                    <div className='row gy-4 gx-4 mb-4'>
                        <div className={`col-lg-6 col-md-12 ${styles['col-custom']}`}>
                            <img src='/img/elysion/crystal-freeze.png' alt='Crystal Freeze System' />
                        </div>
                        <div className={`col-lg-6 col-md-12 ${styles['col-custom']}`}>
                            <h3>Система за охлаждане Crystal Freeze</h3>
                            <strong>
                                <p>За максимален комфорт апаратът е снабден със системата Crystal Freeze, изключителна система за охлаждане на върха на апликатора, която позволява процедурата да бъде максимално комфортна, безопасна и безболезнена, като поддържа постоянна температура на епидермиса до 25 градуса.</p>
                                <p>За разлика от останалите охладителни системи, Crystal Freeze има паралелен ефект, тъй като предотвратява епидермалната топлина в същото време, когато се подават импулсите, което означава, че зоната може да бъде предварително охладена и охладена след всеки импулс.</p>
                                <p>Elysion-Pro 3500W е един от най-бързите, най-безопасните, най-мощните и най-ефективните апарати за лазерна епилация.</p>
                            </strong>
                        </div>
                    </div>
                </div>

                <div className='container wow fadeInUp' data-wow-delay="0.4s">
                    <h2 className='text-center gy-3'>Често Задавани Въпроси</h2>
                    <br />
                    {questions.map((item, index) => (
                        <div key={index}>
                            <h5>{item.question}</h5>
                            <ul>
                                {item.answers.map((answer, idx) => (
                                    <li key={idx} style={{ paddingBottom: '10px' }}>{answer}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                <div className='container wow fadeInUp' data-wow-delay="0.5s">
                    <h2 className='text-center gy-3'>Цени на лазерна епилация с Elysion-Pro</h2>
                    <br />
                    <br />
                    <div className='row gy-4 gx-4 mb-4'>
                        <div className={`col-lg-6 col-md-12 text-center ${styles['col-custom']}`} style={{ alignItems: 'flex-end' }}>
                            <div>
                                <h5>Цени за жени:</h5>
                                <ul style={{ textAlign: 'left' }}>
                                    {prices.women.map((item, index) => (
                                        <li key={index}>{item.area} - {item.price}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className={`col-lg-6 col-md-12 text-center ${styles['col-custom']}`}>
                            <div>
                                <h5>Цени за мъже:</h5>
                                <ul style={{ textAlign: 'left' }}>
                                    {prices.men.map((item, index) => (
                                        <li key={index}>{item.area} - {item.price}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <BackToTopButton />
        </>
    );
}

export default Elysion;
