// import { adjustPrice } from '../utils/priceConverter';

// Data for eyeLashes procedures
const eyeLashesProceduresData = [
    {
        name: 'laminiranemigli',
        price: '50 лв.',
        imageUrl: '/img/eyelashes-1.jpg',
        shortDescription: 'Ламиниране на мигли',
        descriptions: [
            'Ламинирането на мигли е иновативна и все по-предпочитана процедура, която се прилага на естествените мигли, без да се добавят изкуствени косъмчета.',
            'Ламинирането на мигли е напълно безболезнена процедура. Извършена от добър специалист, с висок клас продукти, тя е абсолютно безвредна за миглите. Тя има за цел да подобри естествената им визия.'
        ],
        advantages: ['дълготрайна извивка', 'издължаване', 'траен наситен цвят', 'подхранване и хидратация',],
        prices: ['Цена за 1 бр. процедура - 50 лв.'],
        // promos: [`Цена за 1бр. процедура – От 50 лв. на ${adjustPrice(50, 30)} лв.`,],
    },
    {
        name: 'boqdisvanemigli',
        price: '10 лв.',
        imageUrl: '/img/eyelashes-2.jpg',
        shortDescription: 'Боядисване на мигли',
        descriptions: [
            'Боядисването на мигли се извършва със специално разработена натурална боя за мигли, която е дерматологично и офталмологично тествана, не предизвиква алергични реакции и не дразни деликатната кожа на клепачите.',
            'Придава естествена красота и прави погледа пленителен, миглите  изглеждат много по-дълги и гъсти.'
        ],
        advantages: ['издължаване', 'траен наситен цвят', 'подхранване и хидратация',],
        prices: ['Цена за 1 бр. процедура - 10 лв.'],
    },
    {
        name: 'botoxmigli',
        price: '10 лв.',
        imageUrl: '/img/eyelashes-3.jpg',
        shortDescription: 'Ботокс терапия за мигли',
        descriptions: [
            'Ботокс терапията за мигли, съдържа комплекс от витамини, минерали , стягащи и подхранващи компоненти. Тя поддържа естествената красота на миглите, придава им сила и обем, осигурява ефективна защита от външни фактори на околната среда, укрепва и уплътнява структурата на косъма.',
            'Изключителна процедура за ценителите на естествената красота.'
        ],
        advantages: ['издължаване', 'траен наситен цвят', 'подхранване и хидратация',],
        prices: ['Цена за 1 бр. процедура - 10 лв.'],
    },

];

// Data for eyeBrows procedures
const eyeBrowsProceduresData = [
    {
        name: 'laminiranevejdi',
        price: '40 лв.',
        imageUrl: '/img/eyebrows-1.jpg',
        shortDescription: 'Ламиниране на вежди',
        descriptions: [
            'Ламинирането на вежди е уникална  процедура за създаване на красиви и плътни вежди, чрез подреждане и подобряване на естествения косъм.',
            'Изключително подходяща е за тънки, неравни, “рошави” вежди, с дупчици и/или неправилна форма.',
            'Ако избягвате процедурите, които боравят с пигментация и не понасяте болката, но имате нужда от финален щрих за красиви вежди, ламинирато е вашето решение.'
        ],
        advantages: ['издължаване', 'траен наситен цвят', 'подхранване и хидратация',],
        prices: ['Цена за 1 бр. процедура - 50 лв.'],
        promos: ['Цена за 1бр. процедура – От 50 лв. на 40 лв.',],
    },
    {
        name: 'boqdisvanevejdi',
        price: '10 лв.',
        imageUrl: '/img/eyelashes-2.jpg',
        shortDescription: 'Боядисване на вежди',
        descriptions: [
            'Боядисването на вежди се извършва със специално разработена натурална боя за мигли, която е дерматологично и офталмологично тествана, не предизвиква алергични реакции и не дразни деликатната кожа. Веждите са оформени, подхранени и добиват съвършенна форма.'
        ],
        advantages: ['издължаване', 'траен наситен цвят', 'подхранване и хидратация',],
        prices: ['Цена за 1 бр. процедура - 10 лв.'],
    },
    {
        name: 'pochistvanevejdi',
        price: '10 лв.',
        imageUrl: '/img/eyebrows-2.jpg',
        shortDescription: 'Почистване и оформяне на вежди',
        descriptions: [
            'Правилно оформените и добре поддържани вежди са гаранция за  красиво лице,  изразителен поглед и придадат великолепно излъчване. Важно е да се доверите на професионалист, защото е задължително формата на веждите да е съобразена с тази на лицето и други.'
        ], 
        advantages: ['естествена форма на веждите', 'висококачествени пинсети',],
        prices: ['Цена за 1 бр. процедура - 10 лв.'],
    },
    {
        name: 'botoxvejdi',
        price: '10 лв.',
        imageUrl: '/img/vejdi-3.jpg',
        shortDescription: 'Ботокс терапия на вежди',
        descriptions: [
            'Ботокс терапията е изключително ефективна  процедура за подхранване и възстановяване на  вежди. Комплекса от  биологично активни компоненти повлиява на всички видове проблеми, включително и в участъците със загуба  косъмчета. Тя поддържа естествената красота на веждите, придава им сила и обем, осигурява ефективна защита от външни фактори на околната среда, укрепва и уплътнява структурата на косъма, стимулира растежа.', 'Изключителна процедура за ценителите на естествената красота.'
        ],
        advantages: ['издължаване', 'траен наситен цвят', 'подхранване и хидратация',],
        prices: ['Цена за 1 бр. процедура - 10 лв.'],
    },
];

export {
    eyeLashesProceduresData,
    eyeBrowsProceduresData,
};