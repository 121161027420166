import { Routes, Route } from 'react-router-dom';

import { faceProceduresData } from './data/faceProceduresData.js';
import { eyeLashesProceduresData, eyeBrowsProceduresData } from './data/eyeProceduresData.js';
import { bodyProceduresData } from './data/bodyProceduresData.js';
import { therapiesData } from './data/therpiesData.js';

import NavBar from './components/NavBar.js';
import TopBar from './components/TopBar.js';
import AboutUs from './components/AboutUs.js';
import Footer from './components/Footer.js';
import Contact from './components/Contact.js';
import Promotions from './components/Promotions.js';
import ProcedureList from './components/procedure-list/ProcedureList.js';
import NotFound from './components/NotFound.js';
import ProcedureDetails from './components/procedure-list/ProcedureDetails.js';
import PrivacyPolicy from './components/PrivacyPolicy.js';
import PictureGallery from './components/PictureGallery.js';
import Elysion from './components/lazer-hair-removal/Elysion.js';


function App() {
    return (
        <div>
            <TopBar />
            <NavBar />
            <Routes>
                <Route path="/" element={<AboutUs />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/promotions" element={<Promotions />} />
                <Route path="/elysion" element={<Elysion />} />
                <Route path="/body-procedures" element={<ProcedureList procedures={bodyProceduresData} />} />
                <Route path="/body-procedures/:procedureName" element={<ProcedureDetails procedures={bodyProceduresData} />} />
                <Route path="/eyebrows-procedures" element={<ProcedureList procedures={eyeBrowsProceduresData} />} />
                <Route path="/eyebrows-procedures/:procedureName" element={<ProcedureDetails procedures={eyeBrowsProceduresData} />} />
                <Route path="/eyelashes-procedures" element={<ProcedureList procedures={eyeLashesProceduresData} />} />
                <Route path="/eyelashes-procedures/:procedureName" element={<ProcedureDetails procedures={eyeLashesProceduresData} />} />
                <Route path="/face-procedures" element={<ProcedureList procedures={faceProceduresData} />} />
                <Route path="/face-procedures/:procedureName" element={<ProcedureDetails procedures={faceProceduresData} />} />
                <Route path="/therapies" element={<ProcedureList procedures={therapiesData} />} />
                <Route path="/therapies/:procedureName" element={<ProcedureDetails procedures={therapiesData} />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/gallery" element={<PictureGallery />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/*" element={<NotFound />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;