import { Link } from 'react-router-dom';

import { contactData } from '../data/commonData';

function Footer() {
    const contact = contactData;

    return (
        <div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-3 col-md-6">
                        <h4 className="text-white mb-3">Денас Център</h4>
                        <Link to='/about' className="btn btn-link">За нас</Link>
                        <Link to='/promotions' className="btn btn-link">Промоции</Link>
                        <Link to='/gallery' className="btn btn-link">Галерия</Link>
                        <Link to='/contact' className="btn btn-link">Контакти</Link>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4 className="text-white mb-3">Свържете се с Нас</h4>
                        <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>{contact.street + ', ' + contact.city}</p>
                        <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>{contact.phoneNumber}</p>
                        <p className="mb-2"><i className="fa fa-envelope me-3"></i>{contact.email}</p>
                        <div className="d-flex pt-2">
                            <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/bgdenas"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-outline-light btn-social" href="https://www.instagram.com/denascenter/"><i className="fab fa-instagram"></i></a>
                            {/* <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-youtube"></i></a> */}
                            {/* <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-linkedin-in"></i></a> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4 className="text-white mb-3">Галерия</h4>
                        <div className="row g-2 pt-2">
                            <div className="col-4">
                                <img className="img-fluid bg-light p-1" src="img/face-procedures/electroporaciq-1.jpg" alt="" />
                            </div>
                            <div className="col-4">
                                <img className="img-fluid bg-light p-1" src="img/eyelashes-2.jpg" alt="" />
                            </div>
                            <div className="col-4">
                                <img className="img-fluid bg-light p-1" src="/img/main.png" alt="" />
                            </div>
                            <div className="col-4">
                                <img className="img-fluid bg-light p-1" src="/img/limfopresa.png" alt="" />
                            </div>
                            <div className="col-4">
                                <img className="img-fluid bg-light p-1" src="img/gallery/verju-1.jpg" alt="" />
                            </div>
                            <div className="col-4">
                                <img className="img-fluid bg-light p-1" src="/img/booking.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4 className="text-white mb-3">Работно време</h4>
                        <div>
                            <p className="mb-2">Пон-Пет: 09:00 - 18:00</p>
                            <p className="mb-2">Събота: 10:00 - 14:00</p>
                            <p className="mb-2">Почивен ден: Неделя</p>
                        </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6">
                        <h4 className="text-white mb-3">Newsletter</h4>
                        <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
                        <div className="position-relative mx-auto" style={{ maxwidth: '400px' }}>
                            <input className="form-control border-primary w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                            <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; <Link to={'/'} className="border-bottom"> Denas </Link>. All Right Reserved.
                        </div>
                        <div className="col-md-6 text-center text-md-end">
                            <div className="footer-menu">
                                <Link to='/privacy-policy'><i className="fas fa-lock" /> Политика за поверителност</Link>
                                {/* <Link to='/promotions'>Промоции</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;