import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

function NavBar() {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isProcedureClassNameActive, setIsProcedureClassNameActive] = useState(false);

    const navLinksBefore = [
        { name: 'За Нас', href: '/about' },
        { name: 'Промоции', href: '/promotions' },
    ];

    const navLinksCosmeticProcedures = [
        { name: 'За Тяло', href: '/body-procedures' },
        { name: 'За Лице', href: '/face-procedures' },
        { name: 'За Вежди', href: '/eyebrows-procedures' },
        { name: 'За Мигли', href: '/eyelashes-procedures' },
    ];

    const navLinksAfter = [
        { name: 'Терапии', href: '/therapies' },
        { name: 'Лазерна епилация', href: '/elysion' },
        { name: 'Галерия', href: '/gallery' },
        { name: 'Контакти', href: '/contact' }
    ];

    const handleDropdownToggle = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleDropdownMenuClick = (e) => {
        if (e.target.closest('.dropdown-menu')) {
            setIsProcedureClassNameActive(true);
        } else {
            setIsProcedureClassNameActive(false);
        }
    };

    return (
        <div className="container-fluid position-relative p-0">
            <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
                <Link to="/" className="navbar-brand p-0">
                    <h1 className="text-primary m-0"><i></i>Денас Център</h1>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0" onClick={handleDropdownMenuClick}>
                        {navLinksBefore.map((link) => {
                            return (
                                <NavLink to={link.href} key={link.name}
                                    className={({ isActive }) =>
                                        isActive ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                >
                                    {link.name}
                                </NavLink>
                            );
                        })}
                        <div onMouseEnter={handleDropdownToggle} onMouseLeave={handleDropdownToggle}>
                            <div className={isDropdownOpen ? 'nav-item dropdown show' : 'nav-item dropdown'} aria-expanded={isDropdownOpen ? 'true' : ''}>
                                <Link to="#" data-bs-toggle="dropdown"
                                    className={isProcedureClassNameActive ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'}>
                                    Козметични процедури
                                </Link>
                                <div className={`dropdown-menu m-0 ${isDropdownOpen ? 'show' : ''}`}>
                                    {navLinksCosmeticProcedures.map((link) => {
                                        return (
                                            <NavLink to={link.href} key={link.name}
                                                className={({ isActive }) =>
                                                    isActive ? 'dropdown-item active' : 'dropdown-item'}
                                            >
                                                {link.name}
                                            </NavLink>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        {navLinksAfter.map((link) => {
                            return (
                                <NavLink to={link.href} key={link.name}
                                    className={({ isActive }) =>
                                        isActive ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                >
                                    {link.name}
                                </NavLink>
                            );
                        })}
                    </div>
                </div>
            </nav>

            <div className="container-fluid bg-primary py-5 mb-5 hero-header">
                <div className="container py-5">
                    <div className="row justify-content-center py-5">
                        <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
                            <h1 className="display-3 text-white mb-3 animated slideInDown">Красота, създадена за вас</h1>
                            <p className="fs-4 text-white mb-4 animated slideInDown">Където блясъкът среща вечната привлекателност</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavBar;