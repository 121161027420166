import { useState, useEffect } from 'react';
import SEO from './seo/SEO.js';

import { galleryPicturesData } from '../data/galleryPictureData';

import BackToTopButton from './buttons/BackToTopButton';

const PictureGallery = () => {
    useEffect(() => {
        window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
    }, []);

    const [fullscreenImage, setFullscreenImage] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const pictures = galleryPicturesData;

    const openFullscreen = (image) => {
        setFullscreenImage(image);
    };

    const closeFullscreen = () => {
        setFullscreenImage(null);
    };

    const navigate = (direction) => {
        const newIndex = (currentIndex + direction + pictures.length) % pictures.length;
        setFullscreenImage(pictures[newIndex]);
        setCurrentIndex(newIndex);
    };

    const onFullScreenClick = (e) => {
        if (e.target.tagName === 'IMG') {
            navigate(1);
        } else if (e.target.tagName !== 'SPAN') {
            closeFullscreen();
        }
    };

    return (
        <>
            <SEO
                title={'Галерия - Денас Център'}
                description={'Тук може да разгледате нашият център - модерна апаратура и висок клас консумативи.'}
                canonicalUrl={'https://www.denasbg.com/gallery'}
            />

            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="section-title bg-white text-center text-primary px-3">Галерия</h6>
                    <h1 className="mb-5">Денас Център</h1>
                    <div className="gallery">
                        {pictures.map((picture, index) => (
                            <img
                                key={index}
                                src={picture}
                                alt={`${index + 1}`}
                                onClick={() => openFullscreen(picture)}
                            />
                        ))}
                    </div>

                    {fullscreenImage && (
                        <div className="fullscreen-overlay" onClick={onFullScreenClick} >
                            <div className="fullscreen-container" >
                                <span className="prev" onClick={() => navigate(-1)}>&#10094;</span>
                                <img src={fullscreenImage} alt="Fullscreen" />
                                <span className="next" onClick={() => navigate(1)}>&#10095;</span>
                            </div>
                        </div>
                    )}
                </div>
                {<BackToTopButton />}
            </div>
        </>
    );
};

export default PictureGallery;