import { useEffect } from 'react';
import SEO from '../seo/SEO';

import { Link, useLocation, useParams } from 'react-router-dom';

import BackToTopButton from '../buttons/BackToTopButton';

function ProcedureDetails({
    procedures,
}) {
    useEffect(() => {
        window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
    });

    const { procedureName } = useParams();
    const { imageUrl, shortDescription, descriptions, advantages, packetPrices, prices, faq, promos } = procedures
        .find(x => x.name === procedureName) || {};

    const renderPrices = !promos; // Check if promos pices exist so to not render regular prices.

    const location = useLocation();
    const pathName = location.pathname.split('/')[1];
    const regex = /[А-Я]{5,}|[A-Z]{5,}/;

    return (
        <>
            <SEO
                title={shortDescription}
                description={shortDescription}
                canonicalUrl={`https://www.denasbg.com${location.pathname}`}
            />

            <div className="wow zoomIn" data-wow-delay="0.3s">
                <div className="container col-lg-6 py-3">
                    <h1 className="text-center">{shortDescription}</h1>
                    <br />
                    <div>
                        <div style={{ textAlign: 'left' }} className="row gy-2 gx-4 mb-4">
                            <img className="procedure-image" src={imageUrl} alt="Img" />
                            {advantages &&
                                advantages.map((x, i) =>
                                    <div key={i} className="col-sm-6">
                                        <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>{x}</p>
                                    </div>)}
                        </div>
                    </div>
                </div>
                <div className="container col-lg-8 py-3">
                    <div className="row gy-4 gx-4 mb-4">
                        {descriptions &&
                            <div>
                                <h2 className="text-center gy-3">Допълнителна Информация</h2>
                                <br></br>
                                <strong>{descriptions.map((x, i) =>
                                    <p key={i} className={regex.test(x) ? 'procedure-text-important' : ''}>{x}</p>)}
                                </strong>
                            </div>}
                        {faq &&
                            <div>
                                <h3 className="text-center gy-3">Често Задавани Въпроси</h3>
                                <br></br>
                                {faq.map((x, i) => i % 2 === 0
                                    ? <h5 key={i}>{x}</h5>
                                    : <ul key={i}><li style={{ paddingBottom: '10px' }}>{x}</li></ul>)}

                            </div>}
                        {prices && renderPrices &&
                            <div className="text-center">
                                <h5 >Цената на поцедурите са, както следва:</h5>
                                <div>
                                    {prices.map((x, i) =>
                                        <li key={i}>{x}</li>)}
                                </div>
                            </div>}
                        {promos &&
                            <div className="procedure-promos">
                                <h2>Актуални промоции:</h2>
                                <div>
                                    {promos.map((x, i) =>
                                        <li key={i}><b>{x}</b></li>)}
                                </div>
                            </div>}
                        {packetPrices &&
                            <div className="text-center">
                                <h5>Пакетни цени:</h5>
                                <div>
                                    {packetPrices.map((x, i) =>
                                        <li key={i}>{x}</li>)}
                                </div>
                            </div>}
                    </div>
                </div>
                <div className="d-flex justify-content-center mb-2">
                    <Link to={`/${pathName}`} className="btn btn-sm btn-primary px-3 border-end" style={{ borderRadius: '30px 30px 30px 30px' }}>Обратно към Услуги</Link>
                </div>
            </div>
            {<BackToTopButton />}
        </>
    );
};

export default ProcedureDetails;