const serviceId = 'denas_email_service';

const templateId = 'contact_form';

const publicKey = 'ZeLR8vWMAwCh1wRPM';

export {
    serviceId,
    templateId,
    publicKey,
};