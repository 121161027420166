import { useEffect } from 'react';

import { partnersData } from '../../data/partnersData';

const OurPartners = () => {
    useEffect(() => {
        // Initialize Owl Carousel after component is mounted
        // eslint-disable-next-line no-undef
        $('.testimonial-carousel').owlCarousel({
            autoplay: true,
            smartSpeed: 1000,
            center: true,
            margin: 24,
            dots: true,
            loop: true,
            nav: false,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                992: {
                    items: 3
                }
            }
        });
    }, []); // Empty dependency array ensures this effect runs only once after mount

    return (
        <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container">
                <div className="text-center">
                    {/* <h6 className="section-title bg-white text-center text-primary px-3">
                        Партньори
                    </h6> */}
                    <h1 className="mb-5">Партньори</h1>
                </div>
                <div className="owl-carousel testimonial-carousel position-relative">
                    {partnersData.map((partner) => {
                        return (
                            <div key={partner.name} className="testimonial-item bg-white text-center border p-4">
                                <img
                                    className="bg-white rounded-circle shadow p-1 mx-auto mb-3"
                                    src={partner.img}
                                    style={{ width: 100, height: 100 }}
                                    alt='partner-img'
                                />
                                <h5 className="mb-0">{partner.name}</h5>
                                <p>{partner.address}</p>
                                <p className="mb-0">
                                    {partner.companyInfo}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default OurPartners;
