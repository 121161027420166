// function adjustPrice(priceStr, percentage) {
//     const match = priceStr.match(/(\d+)\s*лв/);
//     if (match) {
//         const originalPrice = parseInt(match[1]);
//         const newPrice = originalPrice * (1 - percentage / 100);
//         return priceStr.replace(/(\d+)\s*лв/, `${newPrice.toFixed(2)} лв`);
//     }
//     return priceStr;
// };

function adjustPrice(price, percentage) {
    return (price * (1 - percentage / 100)).toFixed(0);
};

export { adjustPrice };