/* eslint-disable jsx-a11y/anchor-is-valid */
function BackToTopButton() {
    return (
        <div>
            <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
                <i className="bi bi-arrow-up"></i>
            </a>
        </div>);
}

export default BackToTopButton;