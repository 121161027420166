const benefits = [
    'Трайна епилация',
    'Без врастнали косъмчета',
    'Без увреждане, изгаряне и/или пигментация',
    'Подобрява текстурата на кожата',
    'Безопасна и безвредна процедура',
    'Без болка',
    'През всички сезони',
    'За всички зони',
    'Уникални цени с безупречен резултат'
];

const questions = [
    {
        question: 'Какви са ползите от процедурата с Elysion-Pro?',
        answers: [
            'Дълготрайно обезкосмяване, което се постига за кратък период от време, като в същото време се подобрява състоянието на кожата.',
            'Възможност да се прави епилацията целогодишно, дори и лятото.',
            'Една от най-безболезнените епилации.'
        ]
    },
    {
        question: 'От колко процедури се нуждая?',
        answers: [
            'Броят на процедурите се влияе от вашия фототип, зоната за третиране и спецификите на всеки клиент. Те се определят от вашия специалист. Обикновено процедурите варират между 6 и 10.'
        ]
    },
    {
        question: 'Дълготрайни ли са резултатите?',
        answers: [
            'За да се отстрани трайно окосмяването е необходимо да се проведе курс от няколко сесии. Още след първата процедура трябва да се наблюдава падане на космите.',
            'След приключване на пълния курс се правят 1-2 поддържащи процедури годишно.'
        ]
    }
];

const prices = {
    women: [
        { area: 'Мишници', price: '30 лв.' },
        { area: 'Интим', price: '60 лв.' },
        { area: 'Цели ръце', price: '55 лв.' },
        { area: 'Всяка зона на лицето', price: '25 лв.' },
        { area: 'Цяло лице', price: '55 лв.' },
        { area: 'Лента корем', price: '20 лв.' },
        { area: 'Ареоли', price: '20 лв.' },
        { area: 'Цели крака', price: '100 лв.' }
    ],
    men: [
        { area: 'Мишници', price: '30 лв.' },
        { area: 'Интим', price: '65 лв.' },
        { area: 'Цели ръце', price: '60 лв.' },
        { area: 'Цели крака', price: '150 лв.' },
        { area: 'Гръб', price: '100 лв.' },
        { area: 'Кръст', price: '100 лв.' },
        { area: 'Гърди', price: '70 лв.' },
        { area: 'Корем', price: '70 лв.' }
    ]
};

export {
    benefits,
    questions,
    prices,
};