import { Helmet } from 'react-helmet-async';

const SEO = ({ title, description, canonicalUrl }) => {
    return (
        <Helmet>
            <title>{title}</title>

            <meta name="description" content={description} />

            <link rel="canonical" href={canonicalUrl} />
        </Helmet>
    );
};

export default SEO;