import { Link, useLocation } from 'react-router-dom';

function ProcedureItem({
    name,
    price,
    imageUrl,
    shortDescription,
}) {
    const location = useLocation();
    const pathName = location.pathname;
    
    return (
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="package-item">
                <div className="overflow-hidden">
                    <img className="img-fluid" src={imageUrl} alt="" />
                </div>
                {/* <div className="d-flex border-bottom">
                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-map-marker-alt text-primary me-2"></i>Thailand</small>
                    <small className="flex-fill text-center border-end py-2"><i className="fa fa-calendar-alt text-primary me-2"></i>3 days</small>
                    <small className="flex-fill text-center py-2"><i className="fa fa-user text-primary me-2"></i>2 Person</small>
                </div> */}
                <div className="text-center p-4">
                    <h3 className="mb-0">{price}</h3>
                    <div className="mb-3">
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                    </div>
                    <p>{shortDescription}</p>
                    <div className="d-flex justify-content-center mb-2">
                        <Link to={`${pathName}/${name}`} className="btn btn-sm btn-primary px-3 border-end" style={{ borderRadius: '30px 30px 30px 30px' }}>Прочети повече</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProcedureItem;