const galleryPicturesData = [
    '/img/gallery/pic-1.jpg',
    '/img/gallery/pic-2.jpg',
    '/img/gallery/pic-3.jpg',
    '/img/gallery/pic-4.jpg',
    '/img/gallery/pic-5.jpg',
    '/img/gallery/pic-6.jpg',
    '/img/gallery/pic-7.jpg',
    '/img/gallery/pic-8.jpg',
    '/img/gallery/pic-9.jpg',
    '/img/gallery/pic-10.jpg',
    '/img/gallery/pic-11.jpg',
    '/img/gallery/pic-12.jpg',
    '/img/gallery/pic-13.jpg',
    '/img/gallery/pic-14.jpg',
    '/img/gallery/pic-15.jpg',
    '/img/gallery/pic-16.jpg',
    '/img/gallery/pic-17.jpg',
    '/img/gallery/pic-18.jpg',
    '/img/gallery/pic-19.jpg',
    '/img/gallery/pic-20.jpg',
    '/img/gallery/pic-21.jpg',
    '/img/gallery/pic-22.jpg',
    '/img/gallery/pic-23.jpg',
    '/img/gallery/pic-24.jpg',
];


export { galleryPicturesData };