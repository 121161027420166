import { useEffect, useState, useRef } from 'react';

import SEO from './seo/SEO.js';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

import { contactData } from '../data/commonData.js';
import { serviceId, templateId, publicKey } from '../data/emailjsCredentials.js';

import BackToTopButton from './buttons/BackToTopButton';


function Contact() {
    useEffect(() => {
        window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
    }, []);

    const formRef = useRef();

    const contact = contactData;
    const newForm = {
        name: '',
        email: '',
        number: '',
        subject: 'Процедури за лице',
        message: '',
    };
    const [formValues, setFormValues] = useState(newForm);
    const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);

    const onRecaptchaChange = () => {
        // Set the state to true when reCaptcha is checked
        setIsRecaptchaChecked(true);
    };

    const onChangeHandler = (e) => {
        setFormValues(state => ({
            ...state,
            [e.target.name]: e.target.value,
        }));
    };

    const sendEmail = (e) => {
        e.preventDefault();

        if (formValues.name.trim() === '' ||
            formValues.email.trim() === '' ||
            formValues.number.trim() === '' ||
            formValues.message.trim() === '') {
            return alert('Моля попълнете всички полета');
        }

        if (!isRecaptchaChecked) {
            return alert('Моля, проверете reCaptcha');
        }

        emailjs
            .sendForm(serviceId, templateId, formRef.current, {
                publicKey: publicKey,
            })
            .then(
                () => {
                    setFormValues(newForm);
                    return alert('Запитването е изпратено успешно.');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <>
             <SEO
                title={'Контакти - Денас Център'}
                description={'Свържете се с нас днес и открийте невероятната разлика, която нашите козметични услуги могат да направят. От процедури на лице и тяло до процедури за вежди и мигли.'}
                canonicalUrl={'https://www.denasbg.com/contact'}
            />

            <div className="container-xxl py-5 wow fadeInUp">
                <div className="container">
                    <div className="text-center" data-wow-delay="0.1s">
                        <h6 className="section-title bg-white text-center text-primary px-3">Контакти</h6>
                        <h1 className="mb-5">За запитвания или въпроси</h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6" data-wow-delay="0.1s">
                            <h5>Свържете се с Нас</h5>
                            <p className="mb-4">Ако имате въпроси или се нуждаете от допълнителна информация относно нашите услуги, не се колебайте да се свържете с нас. Ще бъдем повече от щастливи да ви предоставим необходимата информация.</p>
                            <div className="d-flex align-items-center mb-4">
                                <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary" style={{ width: '50px', height: '50px' }}>
                                    <i className="fa fa-map-marker-alt text-white"></i>
                                </div>
                                <div className="ms-3">
                                    <h5 className="text-primary">Адрес</h5>
                                    <p className="mb-0">{contact.street + ', ' + contact.city}</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-4">
                                <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary" style={{ width: '50px', height: '50px' }}>
                                    <i className="fa fa-phone-alt text-white"></i>
                                </div>
                                <div className="ms-3">
                                    <h5 className="text-primary">Телефон за връзка</h5>
                                    <p className="mb-0">{contact.phoneNumber}</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary" style={{ width: '50px', height: '50px' }}>
                                    <i className="fa fa-envelope-open text-white"></i>
                                </div>
                                <div className="ms-3">
                                    <h5 className="text-primary">Имейл</h5>
                                    <p className="mb-0">{contact.email}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <iframe title="Map" className="position-relative rounded w-100 h-100"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2907.463983758428!2d27.918153376601893!3d43.22073078029375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a4546bbf41d6c5%3A0xb05014c8e4051443!2z0YPQuy4g4oCe0JXQstC70L7Qs9C4INCT0LXQvtGA0LPQuNC10LLigJwgMSwgOTAxMCDQm9C10LLRgdC60LgsINCS0LDRgNC90LA!5e0!3m2!1sbg!2sbg!4v1695992986204!5m2!1sbg!2sbg"
                                frameBorder="0" style={{ minheight: '300px', border: '0' }} allowFullScreen="" aria-hidden="false"
                                tabIndex="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.3s">
                <div className="container">
                    <div className="booking p-5">
                        <div className="row g-5 align-items-center">
                            <div className="col-md-12">
                                <h1 className="text-white mb-4">Форма за запитвания</h1>
                                <form ref={formRef} onSubmit={sendEmail}>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input
                                                    name="name"
                                                    type="text"
                                                    className="form-control bg-transparent"
                                                    id="name"
                                                    placeholder="Your Name"
                                                    onChange={onChangeHandler}
                                                    value={formValues.name}
                                                    required
                                                />
                                                <label htmlFor="name">Име и фамилия</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input
                                                    name="email"
                                                    type="email"
                                                    className="form-control bg-transparent"
                                                    id="email"
                                                    placeholder="Your Email"
                                                    onChange={onChangeHandler}
                                                    value={formValues.email}
                                                    required
                                                />
                                                <label htmlFor="email">Имейл адрес</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input
                                                    name="number"
                                                    type="text"
                                                    className="form-control bg-transparent"
                                                    id="number"
                                                    placeholder="Your Number"
                                                    onChange={onChangeHandler}
                                                    value={formValues.number}
                                                    required
                                                />
                                                <label htmlFor="number">Телефон за връзка</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <select
                                                    className="form-select bg-transparent"
                                                    id="subject"
                                                    name="subject"
                                                    onChange={onChangeHandler}
                                                    value={formValues.subject}
                                                    required
                                                >
                                                    <option value={'Процедури за лице'}>Процедури за лице</option>
                                                    <option value={'Процедури за тяло'}>Процедури за тяло</option>
                                                    <option value={'Процедури за вежди и мигли'}>Процедури за вежди и мигли</option>
                                                    <option value={'Други'}>Други</option>
                                                </select>
                                                <label htmlFor="subject">Относно</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea
                                                    name="message"
                                                    className="form-control bg-transparent"
                                                    placeholder="Special Request"
                                                    id="message"
                                                    style={{ height: 100 }}
                                                    onChange={onChangeHandler}
                                                    value={formValues.message}
                                                    required
                                                />
                                                <label htmlFor="message">Вашето съобщение</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-4">
                                            <button
                                                className="btn btn-outline-light w-100 py-3"
                                                type="submit"
                                            >
                                                Изпрати запитване
                                            </button>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center">
                                            <ReCAPTCHA sitekey="6Lfk1YIpAAAAAHIEL8jBxnjKHR1Z0i6Q8U0N2VUt" onChange={onRecaptchaChange} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {<BackToTopButton />}
        </>
    );
}

export default Contact;